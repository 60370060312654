@use "@ey-xd/motif-core/src/styles/themes/design-patterns/4.0/mixins" as mixin;

.eoy-cookie-content {
    height: calc(100vh - 72px);
    overflow-y: auto;
    z-index: 1;
    position: relative;
    background: var(--footer-bg-color);

    h2 {
        padding: 10px 20px;
    }

    .table {
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.05em;
        color: var(--form-element-color);
    }

    .eoy-title {
        line-height: 1.5;
        margin-bottom: 24px;
    }

    .ey-cookie-notification {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0;
        z-index: 1;
        padding: 10px;
        background-color: var(--footer-bg-color);
        width: 100%;
        box-sizing: border-box;
    }

    // Cookie content is also displayed on the privacy page
    // But needs somes slight tweaks

    .privacy-page & {
        margin-top: 50px;
        margin-bottom: 50px;
        color: var(--form-element-color);
    }
}

.cookie-settings {
    background-color: var(--footer-bg-color);
    color: var(--form-element-color);
    margin-bottom: 2rem;
    position: relative;

    &-spacer {
        display: none;
    }

    &-heading {
        display: none;
        width: 100%;
        position: sticky;
        top: 0;

        &-list {
            padding: 0;
            list-style: none;
            display: flex;
            flex-flow: row;
            margin: 0 0 15px 22px;
            justify-content: space-evenly;
        }

        &-item {
            color: var(--banner-bg-color);
            font-weight: 600;
            flex-grow: 1;
            flex-basis: 0;
            padding-left: 20px;
        }
    }

    &-list {
        list-style: none;
        margin: 0;
        padding: 0 0 20px;
    }

    &-item {
        &:nth-of-type(odd) {
            background-color: #333;
        }
    }

    &-detail {
        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &-item {
            padding: 20px;
            border-bottom: 1px solid var(--modal-header-border-color);
            margin: 0;
        }

        &-label {
            color: var(--banner-bg-color);
            margin-bottom: 10px;
            font-weight: 600;
        }

        &-value {
            margin-left: 10px;
            max-width: 700px;
            word-wrap: break-word;
        }
    }

    .cookie-settings-accept {
        margin-left: 10px;

        & + span {
            display: inline-block;
            font-size: 12px;
            transform: translate(0, -2px);
        }
    }
}

@include mixin.mq(medium) {
    .eoy-cookie-content {
        .ey-cookie-notification {
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

@include mixin.mq(large) {
    .eoy-cookie-content {
        height: calc(100vh - 112px);
    }
}

@include mixin.mq(medium) {
    .eoy-cookie-content h2 {
        padding: 30px;
    }

    .cookie-settings-item {
        padding-left: 30px;
        margin-bottom: 0;
    }

    .cookie-settings-detail-item {
        padding: 30px;
    }
}

@include mixin.mq(large) {
    .eoy-cookie-content h2 {
        padding-bottom: 0;
    }

    .cookie-settings-spacer {
        display: block;
    }

    .cookie-settings-heading {
        background: var(--footer-bg-color);
        color: var(--banner-bg-color);
        display: block;
        padding: 10px 0;
        z-index: 2;
    }

    .cookie-settings-detail-list {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
    }

    .cookie-settings-detail-item {
        flex-grow: 1;
        flex-basis: 0;
        border-left: 1px solid var(--modal-header-border-color);
    }

    .cookie-settings-detail-label {
        display: none;
    }

    .cookie-settings-detail-value {
        margin-left: 0;
    }
}
