@use "@ey-xd/motif-core/src/styles/themes/design-patterns/4.0/mixins" as mixin;
@use "../../styles/easings" as easings;

.eoy-hero-container {
    height: 160px;
    width: 100%;
    background-color: var(--hero-bg-color);
    position: relative;
    color: var(--hero-text-color);
    overflow: hidden;
   

    @include mixin.mq(medium) {
        height: 250px;
    }
    
}

.eoy-hero-content-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    .motif-container, .motif-row {
        height: 100%;
    }

    .motif-container {
        padding: 16px 20px;
    
        @include mixin.mq(medium) {
            padding-left: 40px;
            padding-right: 40px;
        }
    
        @include mixin.mq(large) {
            padding: 16px;
            max-width: 1312px;
        }
    }
}

.eoy-hero-bkg-loader {
    display: none;
}

.eoy-hero-bkg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: .25;
    box-shadow: inset -4px -120px 120px -60px var(--hero-bg-color);

    &-loaded {
        animation: fade-bkg-in easings.$ease-out-quad .35s forwards;
    }
}

@keyframes fade-bkg-in {
    from {opacity: 0}
    to {opacity: .25}
}