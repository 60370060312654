@use '@ey-xd/motif-core/src/styles/themes/design-patterns/4.0/foundations/colors.scss' as colors;

body[motif-theme="light"] {
    // motif-overrides    
    --form-element-bg-color: transparent;
    --form-element-hover-bg-color: transparent;
    --form-element-border-color: #{colors.$neutral-00};
    --form-element-placeholder-color: #{colors.$neutral-300};
    --search--bg-color: transparent;
    --search--border-color: none;
    --search-focus--outline-style: none;
    --search--border-style: none;
    --search-focus--border-style: none;
    --search--box-shadow: inset 0 -1px 0 0 var(--form-element-border-color);
    --tab-navigation--color: #{colors.$neutral-900};
    --tab-navigation-active--color: #{colors.$neutral-900};
    --input-placeholder--color: #{colors.$neutral-150};
    --input-placeholder-focus--color: #{colors.$neutral-150};
    --input-placeholder-hover--color: #{colors.$neutral-150};

    /* custom new variables */
    --form-element-color: #{colors.$neutral-00};
    --form-element-hover-color: #{colors.$neutral-300};

    // project specific variable
    --hero-text-color: #{colors.$neutral-00};
    --hero-bg-color: #{colors.$neutral-950};
    --tab-accent-color: #{colors.$neutral-300};
    --avatar-bg-color: #{colors.$neutral-300};
    --header--bg-color: #{colors.$neutral-950};
    --header-title--color: #{colors.$neutral-00};
    --footer-bg-color: #{colors.$neutral-950};
    --footer-text-color: #{colors.$neutral-00};
    --banner-bg-color: #{colors.$yellow-400};
    --dropdown-border-color: #{colors.$neutral-400};
    --dropdown-content-color: #{colors.$neutral-00};
    --dropdown-content-hover-color: #{colors.$neutral-300};
    --modal-footer-bg-color: #{colors.$neutral-00};
    --share-btn-hover-color: #{colors.$neutral-400};
    --card-box-shadow-hover: 0 0 8px 0 rgb(0 0 0 / 30%);
    --all-button-hover-bg: rgb(229 229 233);
    --all-button-border-bottom: rgb(46 46 56);
}
