@use "@ey-xd/motif-core/src/styles/themes/design-patterns/4.0/mixins" as mixin;

.eoy-footer-banner {
    background-color: var(--banner-bg-color);
    padding-top: 24px;
    padding-bottom: 24px;

    .eoy-footer-banner-text {
        font-size: var(--h4--font-size);
        margin-top: 0;
        margin-bottom: 16px;
        font-weight: 400;

        @include mixin.mq(medium) {
          font-size: var(--h2--font-size);
          margin-bottom: 0;
        }
    }

    .eoyfooter-button {
        width: 200px;
    }

    .eoy-button {
        display: flex;
    }
}

.eoy-footer {
    &.motif-footer {
        background-color: var(--footer-bg-color);
        height: auto;
        padding-bottom: 100px;

        &.eoy-footer-slim {
            padding-bottom:24px;
        }

        @include mixin.mq(medium) {
            display: flex;
            justify-content: space-between;
        }
    }
}

.eoy-footer-icon-link {
    display: block;

    .eoy-footer-icon {
        width: 35px;
        height: 35px;
    }
}

.eoy-footer-disclaimer {
    color: var(--footer-text-color);
}

.eoy-page-links {
    background: grey;
    padding: 24px;

    .eoy-footer-link-item {
        display: block;
        color: var(--footer-text-color);
        font-size: var(--h4-font-size);
        font-weight: 300;
        line-height: 26px;
    }
}

@media (max-width: 767px) {
    .eoy-page-links {
        text-align: center;
    }
}
