@use "@ey-xd/motif-core/src/styles/themes/design-patterns/4.0/mixins" as mixin;
@use "../../styles/easings" as easings;

.motif-modal-overlay {
  padding: 0;
}

.motif-modal {
  max-width: 100%;
  height: 100%;
  max-height: 100vh;

  @include mixin.mq(medium) {
    max-height: 90%;
  }

  .motif-modal-body {
    height: auto;
    padding-left: 24px;
    padding-right: 24px;

    .eoy-modal {
      width: 100%;

      .eoy-modal-body {
        display: inline-flex;
        flex-direction: column;
        margin-bottom: 12px;
      

        @include mixin.mq(medium) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;
        }
  
        .eoy-modal-avatar {
          width: 150px;
          min-width: 150px;
          height: 150px;
          background-color: var(--avatar-bg-color);
          border-radius: 80px;
          margin-right: 18px;
          overflow: hidden;
          background-size: cover;
        }
    
        .eoy-modal-personal-details {
          .eoy-modal-fullname {
            margin-bottom: 6px;

            @include mixin.mq(medium) {
              font-size: 18px;
              line-height: 24px;
            }
          }
      
          .eoy-share-icon {
            margin-top: 8px;
          }
    
          .eoy-modal-content {
            margin-top: 0;
            margin-bottom: 4px;

            &-title {
              margin-bottom: 0;
            }

            @include mixin.mq(medium) {
              font-size: 18px;
              line-height: 24px;
              font-weight: 300;
            }
          }
        }
      }

      hr {
        border-top: 1px solid var(--modal-header-border-color);
      }

      .eoy-modal-description-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column-reverse;
        padding-top: 16px;

        @include mixin.mq(large) {
          flex-direction: row;

          .eoy-modal-description {
            width: 67%;
            padding-right: 16px;

            p {
              margin-top: 0;
            }
          }

          .eoy-modal-business-details {
            padding-left: 8px;
            width: 33%;
          }
        }

        .eoy-modal-description p {
          @include mixin.mq(medium) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .eoy-modal-business-details p {
          font-weight: 800;
          margin: 0 0 4px;

          @include mixin.mq(medium) {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 8px;
          }

        }

        p.eoy-web-copy {
          overflow-wrap: break-word;
        }
      }
    }
  }
}

.motif-modal-footer {
  flex-direction: row;
  justify-content: center;
  height: 132px;
  margin-bottom: 48px;
}

@include mixin.mq(medium) {
  .motif-modal {
    max-width: 80%;
    max-height: 90%;

    .motif-modal-body {
      max-height: 100%;
    }

    .motif-modal-footer {
      justify-content: flex-start;
      height: 80px;
      max-height: 80px;
      margin-bottom: 0;
    }
  }
}

@include mixin.mq(large) {
  .motif-modal {
    max-width: 70%;
    height: 70%;
  }
}

@media screen and (max-width: 375px) {
  .motif-modal {
    .motif-modal-header {
      margin: 0 8px;
    }

    .motif-modal-body {
      margin: 0 8px;
    }

    .motif-modal-footer {
      width: 95%;
      margin: 0 8px 48px;
    }
  }
}