@use "@ey-xd/motif-core/src/styles/themes/design-patterns/4.0/mixins" as mixin;

.eoy-cookie-notification {
    background-color: var(--footer-bg-color);
    bottom: 0;
    display: none;
    font-size: 1rem;
    left: 0;
    overflow: auto;
    padding: 60px 4% 5%;
    position: fixed;
    right: 0;
    z-index: 999;

    a {
        color: var(--banner-bg-color);
    }

    &.eoy-state-visible {
        display: block;
    }
}

.eoy-cookie-header {
    margin-top: 0;
}

.eoy-cookie-message,
.eoy-cookie-notification-link {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.eoy-cookie-description {
    color: var(--footer-text-color);
    font-weight: 500;
    line-height: 1.5;

    p {
        color: var(--form-element-color);
        display: inline;
        font-weight: 500;
        line-height: 1.5;
    }
}


@include mixin.mq(medium) {
    .eoy-cookie-notification-inner {
        display: flex;
    }

    .eoy-cookie-message {
        flex: 1 0 80%;
        margin-right: 20px;
    }
}
