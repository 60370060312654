@use "../../styles/easings" as easings;

.motif-tooltip-wrapper {
  .motif-tooltip-trigger-wrap {
    .motif-icon-button {
      &:focus {
        outline: none;
        box-shadow: var(--card-box-shadow-hover);
        background-color: transparent;
      }
    }
  }
}

.motif-tooltip-content {
    .motif-tooltip-wrapper {
      animation: .25s scale-in easings.$ease-out-back;
      transform-origin: bottom center;
    }
  
    .motif-tooltip-content-children {
      margin: 4px;
    }
  }
  
.eoy-share-icon {
  &:hover {
    .motif-icon {
      transition: color .5s easings.$ease-out-quint;
      color: var(--share-btn-hover-color);
    }
  }
}


  @keyframes scale-in {
    from {
      opacity: 0; 
      transform: scaleX(.5) scaleY(.25);
    }
  
    to{
      opacity: 1; 
      transform: scale(1);}
  }